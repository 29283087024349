import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputSelect", "hiddenInput"];

  connect() {
    $(this.inputSelectTarget).selectpicker({
      noneSelectedText: this.inputSelectTarget.dataset.placeholder,
    });

    $(this.inputSelectTarget).on("changed.bs.select", () => {
      this.updateSelectTitle();
      this.updateHiddenInputs();
    });

    this.updateSelectTitle();
    this.updateHiddenInputs();
  }

  updateSelectTitle() {
    const selectedOptions = $(this.inputSelectTarget)
      .find("option:selected")
      .map((_, option) => option.text)
      .get();

    if (selectedOptions.length === 0) {
      $(this.inputSelectTarget)
        .data("title", this.inputSelectTarget.dataset.placeholder)
        .selectpicker("refresh");
    } else {
      $(this.inputSelectTarget)
        .data("title", selectedOptions.join(", "))
        .selectpicker("refresh");
    }
  }

  updateHiddenInputs() {
    const selectedInputs = $(this.inputSelectTarget).val() || [];
    $(this.hiddenInputTarget).empty();
    selectedInputs.forEach((selectableInputs) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = "input[]";
      input.value = selectableInputs;
      this.hiddenInputTarget.appendChild(input);
    });
  }
}
